import React, { useState } from "react";
import { Card, Image, List } from "semantic-ui-react";
import MyVerticallyCenteredModal from "../g-p-modal/g-p-modal.js";
import MyVerticallyCenteredModalDoctorHelper from "../g-p-modal/g-p-modal-helper.js";
import "./g-p-cart.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const SingleGP = ({ el }) => {
  const [modalShow, setModalShow] = useState(false);
  const [showHelperDoctor, setShowHelperDoctor] = useState(false);

  return (
    <Card>
      <Card.Content>
        <Image floated="right" size="mini" src={el.photo} />
        <Card.Header>Д-р {el.name}</Card.Header>
        {el.phone ? (
          <>
            <FontAwesomeIcon
              style={{ color: "rgba(0, 127, 255, 1)" }}
              icon={faPhone}
            />{" "}
            &nbsp;
            <a className="phone-number-doctor" href={"tel:" + el.phone}>
              {el.phone}
            </a>
          </>
        ) : (
          <div>
            <br></br>
          </div>
        )}
        {/* <Card.Meta>Личен лекар</Card.Meta> */}
        <Card.Meta>
          Специалист обща медицина{" "}
          {el.otherSpec ? (
            <>и {el.otherSpec}</>
          ) : (
            <div>
              <br></br>
            </div>
          )}
        </Card.Meta>
        {el.otherSpecialist.length > 0
          ? el.otherSpecialist.map((spec, index) => {
              return (
                <React.Fragment key={"singe_spec_1_" + index}>
                  {spec.helperName && (
                    <Card.Header>Д-р {spec.helperName}</Card.Header>
                  )}
                  {spec.otherSpec ? (
                    <Card.Meta>{spec.otherSpec}</Card.Meta>
                  ) : null}
                </React.Fragment>
              );
            })
          : null}
        {el.mf ? (
          <Card.Header style={{ fontSize: "1em", marginBottom: "22px" }}>
            {el.mf}
          </Card.Header>
        ) : (
          <div key="0">
            <br></br>
            <br></br>
          </div>
        )}
        <MyVerticallyCenteredModalDoctorHelper
          show={showHelperDoctor}
          onHide={() => setShowHelperDoctor(false)}
          el={el}
        />
        <Card.Description>
          <h3>Дейности</h3>
          <List bulleted>
            {el.workingType.length > 0 &&
              el.workingType.map((element, index) => {
                return (
                  <List.Item key={"list_1_" + index} className="type">
                    {element}
                  </List.Item>
                );
              })}
            {el.more &&
              el?.more?.length > 0 &&
              el.more.map((el, index) => {
                return (
                  <List.Item key={"list_2_" + index} className="type">
                    <b>{el}</b>
                  </List.Item>
                );
              })}
          </List>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <button
          className="gp-button gp-blue-button"
          onClick={(e) => {
            e.preventDefault();
            setModalShow(true);
          }}
        >
          {" "}
          Работен График в МЦ Деница
        </button>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          el={el}
        />
      </Card.Content>
      <Card.Content extra>
        {el.helper.length > 0 ? (
          <>
            <button
              className="gp-button gp-red-button"
              onClick={(e) => {
                e.preventDefault();
                setShowHelperDoctor(true);
              }}
            >
              Заместващи лекари
            </button>
          </>
        ) : (
          <button className="gp-button" disabled>
            Няма заместващи лекари
          </button>
        )}
      </Card.Content>
    </Card>
  );
};

export default SingleGP;
