import React from "react";
import Modal from "react-bootstrap/Modal";
import { List } from "semantic-ui-react";

const MyVerticallyCenteredModalSpecalty = (props) => {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <b>{props.el.name} Специалисти</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <List bulleted>
          {props.el.specialists.map((element, index) => {
            return (
              <List.Item className="type" key={"type-speciality_" + index}>
                {element}
              </List.Item>
            );
          })}
        </List>
        {/* {props.el.superdoc ? props.el.superdoc.map(el => <div>dadasdasdasd</div>) : <></>} */}
      </Modal.Body>
      <Modal.Footer>
        <button className="red-close-button" onClick={props.onHide}>
          Затвори
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MyVerticallyCenteredModalSpecalty;
