import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "semantic-ui-css/semantic.min.css";
import Home from "./pages/home/home";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Specialties from "./pages/specialities/specialities";
import Laboratory from "./pages/laboratory/laboratory";
import GP from "./pages/g-p/g-p";
import ClinicalPsychology from "./pages/clinical-psychology/clinical-psychology";
import Results from "./pages/results/results";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/specialities" element={<Specialties />} />
        <Route path="/laboratory" element={<Laboratory />} />
        <Route path="/doctors" element={<GP />} />
        <Route path="/clinical-psychology" element={<ClinicalPsychology />} />
        <Route path="/results" element={<Results/>} />

        <Route path="/*" element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
