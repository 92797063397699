import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const ModalWorikingDays = (props) => {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <b>{props.el.name} Специалисти</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive="xl" striped bordered hover>
          <thead>
            <tr>
              <th></th>
              <th>Понеделник</th>
              <th>Вторник</th>
              <th>Сряда</th>
              <th>Четвъртък</th>
              <th>Петък</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr> */}
            {props.el?.schedule &&
              props.el?.schedule.length > 0 &&
              props.el?.schedule.map((s, index) => {
                return (
                  <tr key={"row_workinkdays_" + index}>
                    {s.map((d, index) => {
                      return <td key={"cell_workinkdays_" + index}>{d}</td>;
                    })}
                  </tr>
                );
              })}
            <tr>
              <td colSpan="6" style={{ textAlign: "center" }}>
                &nbsp;* Графикът подлежи на месечна корекция.
              </td>
            </tr>
          </tbody>
        </Table>
        {props.el.superdoc && props.el.superdoc.map(el => <div style={{marginTop: "15px", textAlign:"center"}}><Button  onClick={(e)=>{e.preventDefault(); openInNewTab(el.url);}}>Запази час при {el.doctor} в Superdoc</Button></div>)}
      </Modal.Body>
      <Modal.Footer>
        <button className="red-close-button" onClick={props.onHide}>
          Затвори
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWorikingDays;
